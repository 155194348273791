export default function Buttons() {
	return (
		<div className="buttons">
			<a className="linkButton" href="/Dokumentace.pdf">
				Dokumentace ( PDF )
			</a>
			<a className="linkButton" href="https://online.dpmp.cz">
				Web ( živá verze, Pardubice )
			</a>
			<a className="linkButton" href="https://global.mhdonline.cz">
				Web ( živá verze, všechna města )
			</a>
			<a className="linkButton" href="https://github.com/ProjectVHD">
				Zdrojový kód ( GitHub )
			</a>
		</div>
	);
}
