export default function Header() {
	return (
		<div className="header">
			<h1 className="mainHeader">Maturitní projekt</h1>
			<h2 className="secondaryHeader">
				Internetová platforma pro dopravní podniky měst ČR pro
				vizualizaci aktuální polohy vozů MHD
			</h2>
			<p>
				Platforma slouží k vizualizaci veřejné hromadné dopravy...
				polohy aktuálních vozidel, čtení jízdních řádů, zobrazování
				trasy jedoucích spojů.
			</p>
			<p>
				Tento projekt je rozdělen do dvou částí: webová aplikace a
				informační backend. Tato část je backendová. Webovou aplikaci
				vytvářel{" "}
				<a
					className="red"
					href="https://b2019volhja.delta-www.cz/maturita/"
				>
					Jan Volhejn
				</a>{" "}
				.
			</p>
		</div>
	);
}
