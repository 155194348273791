import { Link } from "react-router-dom";

export default function Home() {
	return (
		<div>
			<h1>Home</h1>
			<p>Home is where the heart is</p>
			<Link to="/maturita">Maturita</Link>
		</div>
	);
}
