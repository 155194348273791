import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import MaturitaPage from "./pages/maturita";
import Home from "./pages/home";
import Footer from "./components/footer";

const App: React.FC = () => {
	return (
		<>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/maturita" element={<MaturitaPage />} />
				<Route
					path="/dokumentace"
					element={
						<div className="center">
							<a
								className="linkButton"
								id="dokumentaceLink"
								href="/Dokumentace.pdf"
							>
								Dokumentace
							</a>
						</div>
					}
				/>
			</Routes>
			<Footer />
		</>
	);
};

export default App;
