// component that sets the background image for the maturita page
// Path: src/pages/maturita/background.tsx

import React from "react";
import "./maturita.css";

export default function Background() {
	return (
		<>
			<div className="background" />
		</>
	);
}
