import React from "react";
import Background from "./backgorund";
import Header from "./header";
import Buttons from "./buttons";

export default function MaturitaPage() {
	return (
		<>
			<Header />
			<Background />
			<Buttons />
		</>
	);
}
